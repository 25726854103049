// Generated by Framer (838580a)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Arrow from "https://framerusercontent.com/modules/amUBM2bzmV1AZgGGeOOU/Bz9UQAKgfMQMwUGmnZ4r/k7Tpf2jiV.js";
import Button from "./hOJn8v0Cq";
const ButtonFonts = getFonts(Button);
const ArrowFonts = getFonts(Arrow);

const enabledGestures = {LGhatTTcC: {hover: true}};

const cycleOrder = ["LGhatTTcC"];

const serializationHash = "framer-cI6Cd"

const variantClassNames = {LGhatTTcC: "framer-v-1n9h9wa"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 40, delay: 0, mass: 1, stiffness: 615, type: "spring"}

const transformTemplate1 = (_, t) => `translateX(-50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "LGhatTTcC", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppeargnqhaj = activeVariantCallback(async (...args) => {
setVariant(CycleVariantState)
})

useOnVariantChange(baseVariant, {default: onAppeargnqhaj})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1n9h9wa", className, classNames)} data-framer-name={"Remove This Buy Promo"} data-highlight layoutDependency={layoutDependency} layoutId={"LGhatTTcC"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"LGhatTTcC-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-883akd-container"} layoutDependency={layoutDependency} layoutId={"jYVe0Ug77-container"}><Button height={"100%"} id={"jYVe0Ug77"} layoutId={"jYVe0Ug77"} pnkuQ5LAt={"✨ Unlock 95+ Templates"} rjG28skN4={"https://framerbite.com/all-access"} tALPoVrCo={false} variant={"jLRJPCfwS"} width={"100%"}/></motion.div></ComponentViewportProvider><ComponentViewportProvider ><motion.div className={"framer-t43p1r-container"} data-framer-name={"Remove This Arrow"} layoutDependency={layoutDependency} layoutId={"UToVctEWi-container"} name={"Remove This Arrow"} transformTemplate={transformTemplate1}><Arrow height={"100%"} id={"UToVctEWi"} layoutId={"UToVctEWi"} name={"Remove This Arrow"} style={{height: "100%"}} variant={"XHSjoYiUv"} width={"100%"}/></motion.div></ComponentViewportProvider><ComponentViewportProvider ><motion.div className={"framer-1f87tl0-container"} layoutDependency={layoutDependency} layoutId={"AWm1R8vdr-container"}><Button height={"100%"} id={"AWm1R8vdr"} layoutId={"AWm1R8vdr"} pnkuQ5LAt={"Get This Template"} rjG28skN4={"https://finestdevs.com/buy-cloudpeak"} tALPoVrCo={false} variant={"uqEnQDXrk"} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-cI6Cd.framer-k5z9x9, .framer-cI6Cd .framer-k5z9x9 { display: block; }", ".framer-cI6Cd.framer-1n9h9wa { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; min-height: 157px; min-width: 176px; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-cI6Cd .framer-883akd-container { bottom: 0px; flex: none; height: auto; position: absolute; right: -28px; width: auto; z-index: 1; }", ".framer-cI6Cd .framer-t43p1r-container { bottom: 125px; flex: none; height: 90px; left: 89%; position: absolute; width: auto; }", ".framer-cI6Cd .framer-1f87tl0-container { flex: none; height: auto; position: absolute; right: -29px; top: 32px; width: auto; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-cI6Cd.framer-1n9h9wa { gap: 0px; } .framer-cI6Cd.framer-1n9h9wa > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-cI6Cd.framer-1n9h9wa > :first-child { margin-left: 0px; } .framer-cI6Cd.framer-1n9h9wa > :last-child { margin-right: 0px; } }", ".framer-cI6Cd.framer-v-1n9h9wa.hover .framer-t43p1r-container { bottom: 120px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 157
 * @framerIntrinsicWidth 176
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"xDllWO94D":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framers9RGN9DgT: React.ComponentType<Props> = withCSS(Component, css, "framer-cI6Cd") as typeof Component;
export default Framers9RGN9DgT;

Framers9RGN9DgT.displayName = "Button Area";

Framers9RGN9DgT.defaultProps = {height: 157, width: 176};

addFonts(Framers9RGN9DgT, [{explicitInter: true, fonts: []}, ...ButtonFonts, ...ArrowFonts], {supportsExplicitInterCodegen: true})